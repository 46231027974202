import React from "react"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import { runEngine } from "../../../../framework/src/RunEngine"
import { IBlock } from "../../../../framework/src/IBlock"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../framework/src/Message"
import { BuilderLogo, SuccessIcon, ErrorIcon } from "./assets"
import { Slide, Snackbar, SnackbarContent } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import './headerToastBlock.css'

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
interface Props {
  navigation: any

}

interface S {
  message: string | JSX.Element
  open: boolean
  onClose: Function
  type: "success" | "error"
}

interface SS {}

export default class HeaderToastBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.SendMessageToToaster),
    ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    this.state = {
      open: false,
      message: "",
      type: "success",
      onClose: () => {},
    }

    this.receive = this.receive.bind(this)
  }

  render() {
    return (
      <Snackbar
        id="header-toast-container"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          top: 0,
          right: 0,
          height: "auto",
          ...this.getWhiteScreenProps(this.state.type),
        } as CSSProperties}
        open={this.state.open}
        autoHideDuration={!!this.state.type ? 5000 : null}
        onClose={this.handleClose}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#3C3E49",
            borderRadius: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "11px 32px",
            minWidth: "calc(100vw)",
            top: 0,
            right: 0,
            position: "absolute",
            message: {
              width: "100%",
            },
            ...this.getWhiteScreenProps(this.state.type),
          } as CSSProperties}
          message={this.renderMessage()}
          action={
            !this.state.type && (
              <React.Fragment>
                <button
                  className='snackbar-discard-button'
                  onClick={this.discardChanges}
                >
                  Discard changes
                </button>
                <button className='snackbar-save-button'  onClick={this.saveChanges}>
                  Save changes
                </button>
              </React.Fragment>
            )
          }
        />
      </Snackbar>
    )
  }

  getWhiteScreenProps = (type: any) =>
    type === "whitescreen"
        ? {
            backgroundColor: "white",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            position: 'absolute',
        }
        : {};
  saveChanges = () => {
    const requestMessage = new Message(getName(MessageEnum.ActionMessageFromToaster))
    requestMessage.addData(getName(MessageEnum.ActionMessageFromToasterMessage), "SAVECHANGES")
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.handleClose({}, "")
  }

  discardChanges = () => {
    const requestMessage = new Message(getName(MessageEnum.ActionMessageFromToaster))
    requestMessage.addData(getName(MessageEnum.ActionMessageFromToasterMessage), "DISCARDCHANGES")
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.handleClose({}, "")
  }

  handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return
    }
    this.setState({ open: false })
    this.props.navigation.goBack()
    this.state.onClose(event, reason)
  }

  renderMessage = () => {
    let displayedMessage = this.state.message
    if (!displayedMessage) {
      if (this.state.type === "success") displayedMessage = "Your data recorded successfully"
      if (this.state.type === "error") displayedMessage = `Sorry your data couldn't recorded`
    }
    return (
      <div className="snackbar-message-box" style={{ width: this.state.type ? "95vw" : "inherit" }}>
        <div className="snackbar-logo">
          <img src={BuilderLogo} alt="Builder Logo" />
        </div>
        <div className="snackbar-message">
          <div>
            {this.state.type === "success" && (
              <div>
                <img className="snackbar-message-icon" src={SuccessIcon} alt="Success" />
                <span className="snackbar-message-icon-message">Success!</span>
              </div>
            )}
            {this.state.type === "error" && (
              <div>
                <img className="snackbar-message-icon" src={ErrorIcon} alt="Error" />
                <span className="snackbar-message-icon-message">Error!</span>
              </div>
            )}
          </div>
          <div className="snackbar-message-content">{displayedMessage}</div>
        </div>
      </div>
    )
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SendMessageToToaster) === message.id) {
      let messageData = message.getData(getName(MessageEnum.SendMessageToToasterData))
      if (typeof messageData === "string") {
        messageData = JSON.parse(messageData)
      }

      this.setState({
        message: messageData.message,
        open: true,
        type: messageData?.type ?? '',
        onClose: messageData.onClose || this.state.onClose,
      })
    }
  }
}
