Object.defineProperty(exports, '__esModule', {
    value: true
});

// Customizable Area Start
exports.getCurrentPlanMethodType = 'GET';
exports.accountDetailsAPI = 'bx_block_admin_profile/admin_profile';
exports.getCurrentPlanAPI = [`https://api-staging-builder.engineer.ai/api/v1/build_cards/`,"__MARKER__",`/upgrade_plan_prices`];
exports.getCurrentPlanAppToken = 'iCxBWGyfgL89NPCwkc-i';
exports.previewPageLink = process.env.HOST_URL
// Customizable Area End