import React, { useState } from "react";
import { Dialog, DialogProps as _DialogProps } from "./Dialog";
import { isEqual } from "lodash";

export type DialogProps = {
  setDialogState: (newState: boolean, dialogProps: _DialogProps) => void;
};

export const withDialog = (OriginalComponent: any) => (props: any) => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({} as _DialogProps);

  const handleSetDialogState = (
    newDialogState: boolean,
    newDialogProps: _DialogProps
  ) => {
    if (newDialogState) {
      if (!isEqual(newDialogProps, dialogProps))
        setDialogProps(newDialogProps);
    } else {
      setDialogProps({});
    }
    setOpen(newDialogState);
  };

  return (
    <>
      <OriginalComponent {...props} setDialogState={handleSetDialogState} />
      <Dialog {...dialogProps} open={open} setOpen={setOpen} />
    </>
  );
};
