import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export type Props = {
  classes?: any;
};

interface S {
  showWhatsApp: boolean;
}

interface SS {
  id: any;
}
// Customizable Area End

export default class WhatsappWidgetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStoreDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      showWhatsApp: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getStoreDetailsCallId) {
        if (responseJson?.data) {
          this.setState({ showWhatsApp: responseJson.data?.attributes?.is_whatsapp_integration });
          const wpNumber = responseJson.data?.attributes?.whatsapp_number.replace(/\s/g, "") || "";
          const wpMessage = responseJson.data?.attributes?.whatsapp_message || "";

          const wpUrl = `https://wa.me/${wpNumber}?text=${wpMessage}`;
          const wpButton = document.getElementById("WhatsappButton");
          if (wpButton) {
            wpButton.setAttribute("href", wpUrl);
          }
        }
      }
    }
  }

  async componentDidMount() {
    this.getStoreDetails();
  }

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStoreDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeDetailsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
