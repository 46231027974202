import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

export type OpenState = {
  open: boolean;
  setOpen: (newState: boolean) => void;
};

export type DialogProps = {
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  message?: string | React.ReactNode;
  okay?: string;
  cancel?: string;
  okClassName?: string;
  cancelClassName?: string;
  titleClassName?: string;
  messageClassName?: string;
  containerClassName?: string;
  hideCancel?: boolean;
  customContent?: boolean;

  minWidth?: string;
  minHeight?: string;
  width?: string | number;

  confirmDisabled?: boolean;
  confirmColor?: string;
  confirmBackground?: string;
  confirmHoverBackground?: string;
  ignoreClickAway?: boolean;

  children?: React.ReactNode;
};

export const Dialog = withStyles(styles)(
  ({
    open,
    setOpen,
    onClose,
    onSubmit,
    title = "Dialog Title",
    message = "Dialog message...",
    okay = "Confirm",
    cancel = "Cancel",
    okClassName = "",
    cancelClassName = "",
    titleClassName = "",
    messageClassName = "",
    containerClassName = "",
    hideCancel = false,
    customContent = false,
    confirmDisabled = false,
    children,
    classes,
    ignoreClickAway,
  }: DialogProps & OpenState & { classes: any }) => {
    const handleDialogAction =
      (action: any) => async (e: any, reason: string) => {
        const res = await action?.();
        if (res !== false && !(ignoreClickAway && reason === "backdropClick"))
          setOpen(false);
      };

    return (
      <MuiDialog
        open={open}
        onClose={handleDialogAction(onClose)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={containerClassName}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className={titleClassName}
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {title}
          {/* @ts-ignore */}
          <IconButton
            aria-label="close"
            onClick={handleDialogAction(onClose)}
            classes={{
              root: classes.closeButton,
            }}
          >
            <i className="icon-cross" />
          </IconButton>
        </DialogTitle>
        {!customContent && (
          <DialogContent
            classes={{
              root: classes.messageContainer,
            }}
          >
            <DialogContentText
              className={messageClassName}
              classes={{
                root: classes.message,
              }}
            >
              {message}
            </DialogContentText>
          </DialogContent>
        )}
        {children}
        <DialogActions
          classes={{
            root: classes.actionsRoot,
          }}
        >
          {!hideCancel && (
            // @ts-ignore
            <Button
              data-testid="dialog-button-cancel"
              className={cancelClassName}
              classes={{
                root: classes.cancelButton,
              }}
              onClick={handleDialogAction(onClose)}
            >
              {cancel}
            </Button>
          )}
          {/* @ts-ignore */}
          <Button
            data-testid="dialog-button-confirm"
            className={okClassName}
            classes={{
              root: !confirmDisabled ? classes.confirmButton : classes.disabledButton,
            }}
            onClick={handleDialogAction(onSubmit)}
            autoFocus
            disabled={confirmDisabled}
          >
            {okay}
          </Button>
        </DialogActions>
      </MuiDialog>
    );
  }
);
