import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area StartW
import { isTokenExpired, logoutAndRedirectToLoginPage, generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { getStorageData } from "framework/src/Utilities";
import { createRef } from 'react'

interface IGeneralStatus {
  general_seo_setting: {
    is_completed: boolean;
  };
}

interface IStoreDetails {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    };
  };
}

interface IGeoSettingsData {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      store_name: string;
      description: string;
      keywords: string[];
    };
  };
}

interface ISeoData {
  storeName: string;
  description: string;
  keywords: string[];
}

interface IErrorData {
  errors: {
    store_name: string[];
    description: string[];
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  hideLoader:() => void;
  showLoader:() => void;
  showToast:(Object:{message:string,type:"success" | "error"}) => void;
  showHeaderBar: (Object: {}) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  newKeyWordData:string;
  isAddKeywordActive:boolean;
  isFormSubmit: boolean;
  isGeneralSettingsCompletedOnce: boolean;
  seoData:ISeoData,
  seoDataCopy:ISeoData,
  token:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchengineoptimisationseoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSeoSettingsCallId: string = "";
  checkingIsGeneralSettingsAdded: string = "";
  storeDetailsApiId: string = "";
  updateSeoGeneralSettingsId:string = "";
  aiKeywordsGenrationEndPointId:string = "";
  getSeoGenaralSettingsId:string = "";
  addNewInputRef:React.RefObject<HTMLInputElement> = createRef();
  areAiKeysFecthing:boolean = false; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      ,getName(MessageEnum.ActionMessageFromToaster),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isAddKeywordActive:false,
      newKeyWordData:"",
      isGeneralSettingsCompletedOnce: true,
      seoData: { storeName: "", description: "" ,keywords:[]},
      seoDataCopy: { storeName: "", description: "" ,keywords:[]},
      isFormSubmit: false,
      token:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if(message.id === getName(MessageEnum.ActionMessageFromToaster)){
      if (message.getData(getName(MessageEnum.ActionMessageFromToasterMessage)) === configJSON.saveChanges) {

        this.areAiKeysFecthing = false;

        if(this.state.isAddKeywordActive){
          this.setState({ isAddKeywordActive: false, newKeyWordData: '' })
          return
        } 
        this.handleContinueButtonClick();
      } else {
        this.setState(previous => ({ seoData:{...previous.seoDataCopy}}));
      }
    }
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      if(isTokenExpired(message)){
        return logoutAndRedirectToLoginPage(this.props);
      }  
      this.handleResponseMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleNavigate = (route:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage),`admin/${route}`);
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    message.addData(getName(MessageEnum.NavigationRaiseMessage),raiseMessage);
    this.send(message);
  }


  handleBackButton = () => {
    this.handleNavigate("seo-checklist");
  }

  getResponseMessage = (message:Message) => {
    const apiResponseData = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiSuccessMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
  }

  checkValidation = () => {
    const { description, storeName } = this.state.seoData;
    if (storeName.trim() === "" || description.trim() === "") {
      this.setState({ isFormSubmit: true });
      return true;
    }
    return false;
  }

  handleResponseMessage = (message:Message) => {
    const { apiSuccessMessageData, apiErrorMessageData, apiResponseData } = this.getResponseMessage(message);
    this.props.hideLoader();
    try {
      if (!!apiErrorMessageData) {
        this.props.showToast({ message: apiErrorMessageData, type: "error" });
        return;
      }

      if (apiSuccessMessageData.general_seo_setting) {
         [this.checkingIsGeneralSettingsAdded].includes(apiResponseData) && (this.handlingGeneralSettingCheckedMessage(message));
        return;
      }   
      
      if (apiSuccessMessageData.data) {
        [this.storeDetailsApiId].includes(apiResponseData) && (this.handleStoreDetails(message));
        [this.updateSeoGeneralSettingsId,this.getSeoGenaralSettingsId].includes(apiResponseData) && (this.handleUpdateGEOSettings(message));

       return;
     }   
      if (apiSuccessMessageData.ai_results) {
        [this.aiKeywordsGenrationEndPointId].includes(apiResponseData) && this.handleAiGeneratedKeywords(message);
        return
      }

      if (apiSuccessMessageData.errors) {
        [this.updateSeoGeneralSettingsId].includes(apiResponseData) && (this.handleErrors(message));
        return;
      }

      this.props.showToast({message:configJSON.wrongDataFound,type: 'error'});
    }
    catch (error) {
      this.props.showToast({ message: configJSON.networkErrorMsg, type: "error" });
    }
  }

  handleErrors = (message: Message) => {
    let { apiSuccessMessageData } = this.getResponseMessage(message);
    const newObje = apiSuccessMessageData as IErrorData;
    Object.entries(newObje.errors).forEach(([keyData, value]: [string, string[]]) => {
      let messages: string = ""
      value.forEach(value => { messages += value });
      this.props.showToast({ message: `${keyData.split("_").join(" ")} ${messages}`, type: 'error' });
    })
  }

  handleAiGeneratedKeywords = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    if (typeof apiSuccessMessageData.ai_results === "string") {
      const apiData = apiSuccessMessageData.ai_results as string;
      const keywords = apiData === "No candidates found" ? [] : apiData.split(",");
      this.setState(previous => ({ seoData: { ...previous.seoData, keywords } }),()=>{
        this.props.showHeaderBar({});
      });
    }
  }

  generateAIKeywords = async () => {
    const {description,storeName} = this.state.seoData;
    if(this.checkValidation()) return;
    const formdata = new FormData();
    formdata.append("store_name", storeName);
    formdata.append("description", description);
    const keywordsMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
     token:this.state.token,
    }
    keywordsMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.aiKeywordsGenrationEndPoint);
    keywordsMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.postAPIMethod);
    keywordsMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    keywordsMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formdata);
    this.aiKeywordsGenrationEndPointId = keywordsMessage.messageId;
    this.props.showLoader();
    this.areAiKeysFecthing = true;
    runEngine.sendMessage(keywordsMessage.id,keywordsMessage);
    this.setState({isAddKeywordActive:false,newKeyWordData:""})
  }

  getSEOGeneralSettingData = async () => {
    const seoMessage = await generateRequestMessage(configJSON.getSeoSetttingInfoEndPoint,configJSON.getAPIMethod);
    this.getSeoGenaralSettingsId = seoMessage.messageId;
    this.props.showLoader();
    runEngine.sendMessage(seoMessage.id,seoMessage);
  }

  getStoreDescriptionDetails = async () => {
    const {isGeneralSettingsCompletedOnce} = this.state

    if(isGeneralSettingsCompletedOnce) this.getSEOGeneralSettingData();
    else this.getStoreDetails();
  }

  handlingGeneralSettingCheckedMessage = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    const apiData = apiSuccessMessageData as IGeneralStatus;
    this.setState({ isGeneralSettingsCompletedOnce: apiData.general_seo_setting.is_completed },this.getStoreDescriptionDetails);
  }
  

  checkIsGeneralSettingsAdded = async () => {
    const checkMessage = await generateRequestMessage(configJSON.showGeneralSettingsStatusEndPoint, configJSON.getAPIMethod);
    this.checkingIsGeneralSettingsAdded = (checkMessage).messageId;
    this.props.showLoader();
    runEngine.sendMessage(checkMessage.id, checkMessage);
  }

  handleStoreDetails = (message:Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    const apiData = apiSuccessMessageData as IStoreDetails;
    let storeName = '';
    apiData.data.attributes && apiData.data.attributes.name && (storeName = apiData.data.attributes.name)
    this.setState(previous => ({ seoData: {...previous.seoData,storeName}}));
  }

  getStoreDetails = async() => {    
    const storeMessage = await generateRequestMessage(configJSON.storeDetailsEndPoint, configJSON.getAPIMethod);
    this.storeDetailsApiId = (storeMessage).messageId;
    this.props.showLoader();
    runEngine.sendMessage(storeMessage.id, storeMessage);
  }


  handleUpdateGEOSettings = (message:Message) => {
    const { apiSuccessMessageData ,apiResponseData} = this.getResponseMessage(message);
    const apiData = apiSuccessMessageData as IGeoSettingsData;
    let storeName = "" , description = "", keywords:string[] = [];
    if(apiData.data.attributes){
      apiData.data.attributes.store_name && (storeName = apiData.data.attributes.store_name);
      apiData.data.attributes.description && (description = apiData.data.attributes.description);
      Array.isArray(apiData.data.attributes.keywords) && (keywords = apiData.data.attributes.keywords);
    }
    if(apiResponseData === this.updateSeoGeneralSettingsId  && !this.areAiKeysFecthing) {
      this.props.showToast({message:configJSON.detailsUpdationSuccessMessage,type:"success"});    } 
    if(!this.state.isGeneralSettingsCompletedOnce) this.generateAIKeywords();
    this.setState(previous => ({ 
      seoData: { ...previous.seoData, storeName, description, keywords },
      seoDataCopy: { ...previous.seoDataCopy, storeName, description, keywords },
      isGeneralSettingsCompletedOnce:true
    }));
  }

  handleContinueButtonClick = async () => {
    const { description, storeName,keywords } = this.state.seoData;
    if(this.checkValidation()) return;
    const body = {
      general_seo_setting: {
        store_name: storeName,
        description,
        keywords
      }
    }
    this.props.showLoader();
    let endPoint = this.state.isGeneralSettingsCompletedOnce ? configJSON.updateGeneralSettings  : configJSON.createGeneralSettingsEndPoint;
    let method = this.state.isGeneralSettingsCompletedOnce ? configJSON.putAPIMethod :configJSON.postAPIMethod;
    const createGeneralSettingsMsg = await generateRequestMessage(endPoint, method);
    this.updateSeoGeneralSettingsId = createGeneralSettingsMsg.messageId;
    createGeneralSettingsMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(createGeneralSettingsMsg.id, createGeneralSettingsMsg);
  };

  getToken = async () => {
    const token = await getStorageData(configJSON.adminToken);

    this.setState({token},()=>{this.checkIsGeneralSettingsAdded()})
  }

  async componentDidMount(): Promise<void> {
    this.getToken()
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState(previous => ({ seoData: { ...previous.seoData, [event.target.name]: event.target.value },
    isAddKeywordActive:false,
    newKeyWordData:"",
    }));
    this.state.isGeneralSettingsCompletedOnce && this.props.showHeaderBar({});
  }

  getDescriptionErrorData = () => {
    return (this.state.isFormSubmit && this.state.seoData.description.trim() === "");
  }

  getStoreNameErrorData = () => {
    return (this.state.isFormSubmit && this.state.seoData.storeName.trim() === "");
  }
  handleChipDelete = (chipId:number) => {
    this.setState(previous => {
      const { keywords } = previous.seoData;
      return { seoData: {...previous.seoData, keywords: keywords.filter((item, indexId) => indexId!== chipId) },
      isAddKeywordActive:false,
      newKeyWordData:""
     }
    })
    this.props.showHeaderBar({});
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      const {newKeyWordData} = this.state;
      if(newKeyWordData.trim() === ""){
        this.setState({isAddKeywordActive:false,newKeyWordData:""});
        return;
      }
      this.setState(previous => ({
        isAddKeywordActive: false,
        seoData:{...previous.seoData,keywords:[...previous.seoData.keywords, previous.newKeyWordData]},
        newKeyWordData:"",
      }));
     this.state.isGeneralSettingsCompletedOnce && this.props.showHeaderBar({});
    }
  }

  handleAddChipText = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({newKeyWordData:event.target.value});
  }

  handleAddNewChip = () => {
    this.setState({ isAddKeywordActive: true },()=>{
    this.addNewInputRef.current?.focus();
    });
  }

  getAiButtonDisableStatus = () => {
    const { seoData, seoDataCopy } = this.state;
    if (seoData.storeName.trim() === seoDataCopy.storeName.trim() && seoData.description.trim() === seoDataCopy.description.trim()) {
      if (seoData.keywords.length === 0) return false;
      return true;
    }
    return false;
  }

  // Customizable Area End
}
