export const eventEmitter: any  = {
    _events: {},
    dispatch(event: any, data: any) {
      if (!this._events[event]) return;
      this._events[event].forEach((callback: any) => callback(data))
    },
    subscribe(event: any, callback: (data: any) => any) {
      if (!this._events[event]) this._events[event] = [];
      this._events[event].push(callback);
    },
    unsubscribe(event: any) {
      if (!this._events[event]) return;
      delete this._events[event];
    }
  }