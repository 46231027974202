import React from "react";

// Customizable Area Start
import { Box, Button, Typography, Grid, Modal, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from '../../ss-cms-common-components/src/Dialog/withDialog';
import withSnackBar from '../../ss-cms-common-components/src/HOC/withSnackBar.Web';
import withLoader from '../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';

interface ICard {
  tagId:string;
  image:string;
  description:string;
  title:string;
  script:string | null;
}
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBottomButtons = (isEditing: boolean) => {
    return (
      <Box sx={
        webStyle.buttonContainerStyle
      }>
        {isEditing ?
          <Button onClick={this.handleDelete} data-testid='delete button' className='cancel_button red_text_color'>
            {configJSON.deleteButton}
          </Button>
          :
          <Button onClick={this.handleHowToUse} data-testid='howToUseButton' className='cancel_button purple_text_color'>
            {configJSON.howToUseText}
          </Button>
        }
        <Box sx={webStyle.buttonResponsiveCon}>
          <Button onClick={this.toggleModal} className='cancel_button' data-testid='cancel_button'>
            {configJSON.cancelButton}
          </Button>
          <Button onClick={this.handleAdd} className='save_button' data-testid='save_button'>
            {isEditing ? configJSON.saveButtonLabel :configJSON.connectButton}
          </Button>
        </Box>
        
      </Box>
    );
  }

  renderCard = (card:ICard) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={card.tagId}>
        <Box sx={webStyle.cardContainer}>
          <Box className='image_box_container'>
            <img src={card.image} alt={card.title} />
          </Box>
          <Box className="description_container">
            <Box className="title_container" >
              <Typography className='title' data-testid='title'>
                {card.title || configJSON.cardTitle}
              </Typography>
            </Box>
            <Typography className='description'>{card.description || configJSON.cardDescription}</Typography>
            <Button variant="outlined"
              className='button'
              data-testid='button'
              onClick={this.handleConnect} >
              {card.script ? configJSON.editButton : configJSON.connectButton}
            </Button>
          </Box>
        </Box>
      </Grid>
    )
  }

  renderModal = () => {
    return (
      <Modal data-testid='modal' open={this.state.isModalOpen} onClose={this.toggleModal} style={webStyle.modalStyles}>
        <Box sx={webStyle.cardModalContainer}>
          <Box sx={webStyle.modalTextCon}>
            <Typography className="modal_title" data-testid='modal title' variant="h2">{configJSON.modalTitle}</Typography>
              <Box  sx={webStyle.modalInputContainer}>
                <Typography className='textFeildLabel'>{configJSON.addGTMLabel}</Typography>
                <TextField
                  className="textFeild"
                  variant="outlined"
                  data-testid='text feild'
                  value={this.state.txtInputValue}
                  FormHelperTextProps={{style:webStyle.helperTextStyle}}
                  multiline
                  helperText={this.state.erroText}
                  minRows={configJSON.minNumberRows}
                  onChange={this.handleTextChange} />                  
              </Box>
          </Box>
          {this.renderBottomButtons(!!(this.state.googleTagScript.tagId && this.state.googleTagScript.script))}
        </Box>
      </Modal>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainContainer}>
          <Typography className='other_heading' data-testid='analitics-heading' variant="h2">{configJSON.otherIntegrationTitle}</Typography>
          {this.renderCard(
           this.state.googleTagScript
            )}
          {this.renderModal()}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const setToTwoLines = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  wordBreak: "break-all"

}

const webStyle = {
  mainContainer: {
    flexDirection:"column",
    padding:"1rem",
    "& .other_heading": {
      padding:"1rem 0rem 2rem",
      fontFamily: 'Rubik',
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      color:"#3C3E49",
    }
  },
  helperTextStyle:{
   color:"red",
   marginLeft:"-2px"
  },
  cardContainer: {
    width: "min(100%,320px)",
    borderRadius: 5,
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px #F5F5F5',
    "& > .image_box_container": {
      height: "153px",
      width: "100%",
      backgroundColor: "#ECEDF0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: ".5rem",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }
    },
    "& > .description_container": {
      padding: "1rem",
      width: "100%",
      display: "flex",
      flexDirection: "column" as 'column',
    },
    "&  .title_container": {
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",
    },
    "& .title": {
      color: '#000',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "1",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word"
    },
    "& .description": {
      color: '#8C8C8C',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Rubik',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      overflow: "hidden",
      marginTop:".5rem",
      marginBottom:"1.3rem",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2",
      wordBreak: "break-word"
    },
    "& .button": {
      borderRadius: "5px",
      border: "1.5px solid #364F6B",
      width: "100%",
      height: "48px",
      textTransform: "none",
      color: "#364F6B",
    }
  },
 cardModalContainer: {
   bgcolor: "#FFF",
   height: "min(100%,420px)",
   borderRadius: "2px",
   width: "min(100%,686px)",
   display: "flex",
   flexDirection: "column" as 'column',
   position: "relative" as 'relative',
 },
 buttonContainerStyle: {
   height: "4rem",
   borderTop: "1px solid #E8E8E8",
   bgcolor: "#FFFFFF",
   width: "100%",
   marginTop: "auto",
   display: "flex",
   justifyContent: "sapce-between",
   flexShrink: 0,

   "& .cancel_button": {
     color: '#3C3E49',
     textAlign: 'center',
     fontFeatureSettings: "'clig' off, 'liga' off",
     fontFamily: 'Rubik',
     fontSize: 12,
     fontStyle: 'normal',
     fontWeight: 400,
     lineHeight: '20px',
     textDecoration: 'underline',
     textTransform: "capitalize",
     padding: ".8rem 3rem",
     height: "inherit"
   },
   "& .red_text_color": {
     color: "#FF1744",
   },
   "& .purple_text_color":{
    color: "#6200EA",
    textDecorationColor:"#6200EA"

   }
 },
 buttonResponsiveCon: {
   height: "100%",
   marginLeft: "auto",
   "& .cancel_button": {
     color: '#3C3E49',
     textAlign: 'center',
     fontFeatureSettings: "'clig' off, 'liga' off",
     fontFamily: 'Rubik',
     fontSize: 12,
     fontStyle: 'normal',
     fontWeight: 400,
     lineHeight: '20px',
     textDecoration: 'underline',
     textTransform: "capitalize",
     padding: ".8rem 3rem",
     height: "inherit"
   },
   "& .save_button": {
     borderRadius: 3,
     background: "#00D659",
     color: '#3C3E49',
     textAlign: 'center',
     fontFamily: 'Rubik',
     fontSize: 16,
     fontStyle: 'normal',
     fontWeight: 500,
     lineHeight: '24px',
     textTransform: "capitalize",
     padding: ".8rem 3rem",
     height: "inherit"
   }
 },
 modalStyles: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   width: "100%",
   height: "100vh",
   padding: "max(5%,1rem)"
 },
 modalTextCon: {
   display: "flex",
   padding: "2rem",
   gap: "2rem",
   flexDirection: "column" as "column",
   "& .modal_title": {
     fontFamily: 'Rubik',
     fontFeatureSettings: "'clig' off, 'liga' off",
     fontSize: 20,
     letterSpacing: '-0.4px',
     fontWeight: 500,
     fontStyle: 'normal',
     color: "#3C3E49",
     lineHeight: '28px',
   }
 },
 modalInputContainer: {
   display: "flex",
   gap: ".5rem",
   flexDirection: "column" as "column",
   "& .textFeildLabel": {
     fontFeatureSettings: "'clig' off, 'liga' off",
     color: "#000",
     fontSize: "12px",
     fontFamily: "Rubik",
     fontWeight: 500,
     textAlign: "left",
     fontStyle: "normal",
     lineHeight: "20px",
   },
   "& .textFeild": {
     borderColor: "#D0D2DA",
     color: "#000",
     width: "100%",   
     "& .MuiInputBase-root:hover": {
       outline: "none",
       borderColor: "#D0D2DA",
       backgroundColor: "transparent",
     },
    
     "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      borderColor: "#D0D2DA",
      outline: "none",
    },

     "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
     {
       borderColor: "#D0D2DA",
       borderRadius: "3px",
       outline: "none",
       borderWidth: "1px",
     },
     "& .MuiFormHelperText-root": {
      color: "red",
    },

     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "3px",
      outline: "none",
      borderWidth: "1px",
      borderColor: "#D0D2DA",
    },
    "& fieldset": {
      borderRadius: "3px",
      borderColor: "#D0D2DA !important",
    },

     "& .MuiInput-underline:": {
       borderColor: "#D0D2DA",
       borderRadius: "3px",
     },
     "&:hover fieldset": {
       borderColor: "#D0D2DA !important",
       borderRadius: "3px",
     },
  
     "& .MuiInputBase-input": {
       width: "100%",
     },
    
   },
 },
};

export const NewAnalytics = withLayout(
  withSnackBar(withLoader(withDialog(withHeaderBar(Analytics))))
)
// Customizable Area End
