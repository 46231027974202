import React from "react"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import { runEngine } from "../../../../framework/src/RunEngine"
import { IBlock } from "../../../../framework/src/IBlock"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../framework/src/Message"
import { CircularProgress } from "@material-ui/core"
import "./loaderWebBlock.css"

interface Props {
  navigation: any
}

interface S {
  open: boolean
}

interface SS {}

export default class LoaderWebBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [getName(MessageEnum.ShowLoader), getName(MessageEnum.HideLoader)]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    this.state = {
      open: false,
    }

    this.receive = this.receive.bind(this)
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress size="5rem" color="primary" data-testid="page-loader" />
      </div>
    )
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.ShowLoader) === message.id) {
      this.setState({
        open: true,
      })
    }
    if (getName(MessageEnum.HideLoader) === message.id) {
      this.setState({
        open: false,
      })
      this.props.navigation.goBack()
    }
  }
}
