import MessageEnum from "../../../../framework/src/Messages/MessageEnum";

enum CustomMessageEnum {
  FilterCheckedMessage,
  FilterCheckedMessageData,
  removeFilter,
  removeFilterData,
  UpdateWishlist,
  UpdateWishlistLen,
  Token,
  onboardingResponse,
  ActionMessageFromToaster,
  ActionMessageFromToasterMessage,
  AddStateHeaderMessage,
  AddStateHeaderMessageData
}

type MergedMessageEnum = CustomMessageEnum | MessageEnum;
const MergedMessageEnum = { ...CustomMessageEnum, ...MessageEnum };

export const getName = (myEnum: MergedMessageEnum) => {
  return { ...CustomMessageEnum, ...MessageEnum }[myEnum];
};

export default MergedMessageEnum;
