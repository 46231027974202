import * as React from 'react';
// Customizable Area Start
import { useState, useEffect } from 'react';
import { List, Collapse } from '@material-ui/core';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppMixpanel as mixpanel } from "../../../../components/src/MixPanel";
import { getStorageData } from 'framework/src/Utilities';
interface RenderLinkProps extends RouteComponentProps {
    data: any
    tierType?: string
}

const RenderLink: any = withRouter((props: RenderLinkProps) => {
  const { data, location, tierType } = props;
  const haveSubLinks = Array.isArray(data.subLinks) && data.subLinks.length > 0;
  const isActive =
      data.link === "/admin" || data.link === "/admin/"
          ? location.pathname === "/admin" || location.pathname === "/admin/"
          : location.pathname.startsWith(data.link);

  const initialOpen = !haveSubLinks
      ? false
      : data.subLinks.some((i: any) => location.pathname.startsWith(i.link));
  const [isOpen, toggleOpen] = useState(initialOpen);

    useEffect(() => {
        if (haveSubLinks) {
            if (isActive) {
                toggleOpen(true)
            }
        }
    }, [])
    useEffect(() => {
        if (haveSubLinks) {
            const isActive = data?.subLinks?.map((link:any)=>{return link?.link}).includes(location.pathname);
            if(isActive){
                toggleOpen(true)
            }
        }
    }, [location])

    return (
        <List className='admin-nav__item'>
            <Link
                to={data.link || '#'}
                className={`admin-nav__link ${isActive ? 'active' : ''}`}
                onClick={
                    async(e) => {
                        e.preventDefault();
                        if (haveSubLinks) {
                            toggleOpen(!isOpen)
                        } else if (data.link) {
                            const adminUsre = await getStorageData("adminuser")
                            if(adminUsre){
                                const adminData = JSON.parse(adminUsre).data.attributes
                                data.link == "/admin/products" && mixpanel.track('view_product_tab',{email:adminData.email,buildCardId:adminData.build_card});
                                data.link == "/admin/variants" && mixpanel.track('varient_menu',{email:adminData.email,buildCardId:adminData.build_card});
                                data.link == "/admin/Branding" && mixpanel.track('click_branding_menu',{email:adminData.email,buildCardId:adminData.build_card});

                            }
                            props.history.push(data.link)
                        }
                    }
                }
            >
                <div className='d-inline-flex align-items-center'>
                    <i className={`${data.iconName} admin-nav__lin-icon`}></i>
                    &nbsp;
                    &nbsp;
                    <span>{data.label}</span>
                </div>
                {
                    haveSubLinks && (
                        <i className={`icon-chevron-${isOpen ? 'up' : 'down'} admin-nav__angle-icon f-10`}></i>
                    )
                }
            </Link>
            {
                haveSubLinks && (
                    <Collapse in={isOpen} className="mt-3">
                        {
                            data.subLinks.map((subLinkData: any) => <RenderLink tierType={tierType} data={subLinkData} />)
                        }
                    </Collapse>
                )
            }
        </List>
    );
})

export default RenderLink;
// Customizable Area End