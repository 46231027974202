import React from "react";
// Customizable Area Start
import { withStyles, WithStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

const styles = () => ({
    input: {
        borderRadius: 8,
        border: "1px solid #CBD5E1",
        fontSize: 16,
        padding: "10px 44px 10px 8px !important",
        fontFamily: "Inter",
        textTransform:'capitalize' as 'capitalize',
        "&:focus": {
            borderRadius: 8,
            backgroundColor: "white"
        }
    }
});

interface VariantPropertySelectorProps {
    title: string;
    options: {
        value: string;
        label?: string;
        disabled?: boolean;
    }[];
    onChange?: SelectProps["onChange"];
    onOpen?: SelectProps["onOpen"];
    value: string;
}

class VariantPropertySelector extends React.Component<VariantPropertySelectorProps & WithStyles<typeof styles>,{}> {
    render() {
        const { classes, title, options, onChange, onOpen, value } = this.props;
    return (
        <div className="variant-property-selector">
            <p>{title}</p>
            <Select
                onChange={onChange}
                onOpen={onOpen}
                value={value}
                input={<InputBase classes={{ input: classes.input }} style={{color:"var(--design-secondary-color)"}} />}
                IconComponent={KeyboardArrowDownRoundedIcon}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    PaperProps: {
                        style: {
                            color: "#0F172A"
                        }
                    }
                }}
            >
                {
                    options.map(option => (
                        <MenuItem key={option.value} value={option.value} disabled={option.disabled} style={{color:"var(--design-secondary-color)",textTransform:'capitalize'}}>
                            {option.label}
                        </MenuItem>
                    ))
                }
            </Select>
        </div>

    );
};
}

export default withStyles(styles)(VariantPropertySelector);
// Customizable Area End