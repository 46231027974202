import React from 'react'
// Customizable Area Start
import WhatsappWidgetController, { configJSON } from './WhatsappWidgetController.web';
import './assets/WhatsappWidget.css';
const svg_whatsapp = require('./assets/whatsapp.svg');
// Customizable Area End

class WhatsappWidget extends WhatsappWidgetController {
  // Customizable Area Start
  render() {
    return (
      this.state.showWhatsApp &&
        <a id="WhatsappButton" target="_blank" title={configJSON.wpButtonTitle}>
          <img src={svg_whatsapp} alt="whatsapp" />
        </a>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default WhatsappWidget;
// Customizable Area End