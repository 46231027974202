Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.emailLabel = "Email";
exports.emailPlaceholder = "Your email";
exports.nextButton = "Next";
exports.dontHaveAccount = "Don’t have an account?";
exports.signUp = "Sign up";
exports.emailValidation = /^[\w.]+@([a-zA-Z]+\.)+[a-zA-Z]{2,4}$/;
exports.forgotpass = "Forgot Password?";
exports.or = "OR";
exports.longPass = "Long password? Hard to type?";
exports.wecanSendText = "We can send you a magic link via email, allowing you to sign in without needing to enter your password."
exports.mailmeLink = "Email Me A Link";
exports.backTo = "Back to";
exports.requiredField = "Password is required.";
exports.noAccount = "No account registered with this email";
exports.magicLinkSent = "Magic Link Sent!";
exports.checkMail = "Check your email";
exports.weMailedText = "We emailed a magic link to ";
exports.weMailedText1 = "We've sent a magic link to your email. Please check your inbox and click the link to log in. If you don't see the email, check your spam or junk folder.";
exports.clickLink = "Click the link to log in.";
exports.resendMailButton = "Resend e-mail";
exports.homeLink = "Home";
exports.profileLink = "Profile";
exports.accountTitle = "ACCOUNT";
exports.accountAction = "ACCOUNT ACTIONS";
exports.personalDetails = "Personal details";
exports.manageAddress = "Manage Addresses";
exports.order = "Orders";
exports.logout = "Log out";
exports.deleteAccount = "Delete account";
exports.enterValidPassword = "Enter valid password.";
exports.magicLinkError = "Something went wrong.";
exports.validationApiContentType = "application/json";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.passwordRecov = "Password recovery";
exports.noWorriesText = "No worries, we'll send a recovery link to your email.";
exports.sendRecov = "Send a recovery link";
exports.weSentReset = "We have sent a password reset link.";
exports.setNewPw = "Set New Password";
exports.newPw = "New Password";
exports.confirmPw = "Confirm New Password";
exports.placeholderNewPw = "Your new password";
exports.placeholderConfirmPw = "Confirm your new password";
exports.passwordLengthError = "Password must be at least 6 characters long";
exports.passwordMismatch = "Password mismatch.";
exports.successReset = "Password Reset Successful";
exports.successResetDesc = "Your password has been successfully reset.";

exports.loginPasswordEndpoint = "/bx_block_login/logins";
exports.checkMailEndPoint = "/account_block/accounts/check_email";
exports.magicLinkEndpoint = "/account_block/accounts/magic_link";
exports.forgotPasswordEndpoint = "/bx_block_forgot_password/passwords/send_mail";
exports.resetPasswordEndpoint = "/bx_block_forgot_password/passwords/reset_password";
exports.enterYourPlaceholder = "Your password";
exports.logout = "Log out";
exports.apiDeleteUser = "DELETE";
exports.apiEndPointDeleteUserWeb = "/account_block/accounts";

// Customizable Area End