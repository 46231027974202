import React from "react"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import { runEngine } from "../../../../framework/src/RunEngine"
import { IBlock } from "../../../../framework/src/IBlock"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum"
import { Message } from "../../../../framework/src/Message"
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  navigation: any
  classes: any

}

interface S {
  open: boolean
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  message?: string | React.ReactNode;
  okay?: string;
  cancel?: string;
  okClassName?: string;
  cancelClassName?: string;
  titleClassName?: string;
  messageClassName?: string;
  containerClassName?: string;
  hideCancel?: boolean;
  customContent?: boolean;
  minWidth?: string;
  minHeight?: string;
  width?: string | number;
  confirmDisabled?: boolean;
  confirmColor?: string;
  confirmBackground?: string;
  confirmHoverBackground?: string;
  ignoreClickAway?: boolean;
  dialogResponseId?: string;
  additionalInfo?: any;
}

interface SS { }


export class DialogBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.ShowDialog),
      getName(MessageEnum.HideDialog),
    ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    this.state = {
      open: false,
      onClose: () => { },
      ignoreClickAway: false,
      title: "Dialog Title",
      message: "Dialog message...",
      okay: "Confirm",
      cancel: "Cancel",
      okClassName: "",
      cancelClassName: "",
      titleClassName: "",
      messageClassName: "",
      containerClassName: "",
      hideCancel: false,
      customContent: false,
      confirmDisabled: false,
      dialogResponseId: "",
      additionalInfo: {},
    }

    this.receive = this.receive.bind(this)
  }

  async receive(_from: string, message: Message) {
    if (getName(MessageEnum.ShowDialog) === message.id) {
      let messageData = message.getData(getName(MessageEnum.ShowDialogData))
      if (typeof messageData === "string") {
        messageData = JSON.parse(messageData)
      }

      this.setState({
        open: true,
        ...messageData
      })
    } else if (getName(MessageEnum.HideDialog) === message.id) {
      this.props.navigation.goBack()
    }
  }
  handleDialogAction = (e: any, reason: string) => {
    if (reason === "backdropClick" && this.state.ignoreClickAway) {
      return
    }

    this.handleClose()
  }

  handleClose = () => {
    const requestMessage = new Message(getName(MessageEnum.DialogResponse))
    requestMessage.addData(getName(MessageEnum.DialogResponseData), false)
    const responseMessage = {
      dialogResponseId: this.state?.dialogResponseId ?? "",
      status: 'REJECTED',
      additionalInfo: this.state?.additionalInfo ?? {},
    }
    requestMessage.addData(getName(MessageEnum.DialogResponseData), responseMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.props.navigation.goBack()
  }

  handleConfirm = () => {
    const responseMessage = {
      dialogResponseId: this.state?.dialogResponseId ?? "",
      status: 'ACCEPTED',
      additionalInfo: this.state?.additionalInfo ?? {},
    }
    const requestMessage = new Message(getName(MessageEnum.DialogResponse))
    requestMessage.addData(getName(MessageEnum.DialogResponseData), responseMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.props.navigation.goBack()
  }

  render() {
    return (
      <MuiDialog
        open={this.state.open}
        onClose={this.handleDialogAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={this.state.containerClassName}
        classes={{
          paper: this.props.classes.dialogPaper,
        }}
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className={this.state.titleClassName}
          classes={{
            root: this.props.classes.dialogTitle,
          }}
        >
          {this.state.title}
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            classes={{
              root: this.props.classes.closeButton,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {!this.state.customContent && (
          <DialogContent
            classes={{
              root: this.props.classes.messageContainer,
            }}
          >
            <DialogContentText
              className={this.state.messageClassName}
              classes={{
                root: this.props.classes.message,
              }}
            >
              {this.state.message}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions
          classes={{
            root: this.props.classes.actionsRoot,
          }}
        >
          {!this.state.hideCancel && (
            <Button
              data-testid="dialog-button-cancel"
              className={this.state.cancelClassName}
              classes={{
                root: this.props.classes.cancelButton,
              }}
              onClick={this.handleClose}
            >
              {this.state.cancel}
            </Button>
          )}
          <Button
            data-testid="dialog-button-confirm"
            className={this.state.okClassName}
            classes={{
              root: this.props.classes.confirmButton,
            }}
            style={this.state.confirmBackground
              ? { backgroundColor: this.state.confirmBackground } : {}
            }
            onClick={this.handleConfirm}
            autoFocus
            disabled={this.state.confirmDisabled}
          >
            {this.state.okay}
          </Button>
        </DialogActions>
      </MuiDialog>
    )
  }


}

export default withStyles(styles, { withTheme: true })(DialogBlock)
