Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.checkMailEndPoint = "/account_block/accounts/check_email";
exports.agreeWith = "I agree with";
exports.privacyPolicy = "Privacy Policy";
exports.and = "and";
exports.termCondition = "Terms and Conditions";
exports.nextButton = "Next";
exports.alreadyAccount = "Already have an account?";
exports.login = "Log in";
exports.emailValidation = /^[\w.]+@([a-zA-Z]+\.)+[a-zA-Z]{2,4}$/;
exports.invalidEmail = "Invalid email";
exports.pleaseAgreeError = "Please agree to Privacy Policy and Terms and Conditions to Sign up";
exports.placeholderPass = "Your Password";
exports.requiredField = "Password is required";
exports.signUpPassButton = "Sign up using password";
exports.passwordLengthError = "Password must be at least 6 characters long";
exports.or = "OR";
exports.longPassword = "Long password? Hard to type?";
exports.wecanSend = "We can send you a magic link via email, allowing you to sign in without needing to enter your password."
exports.mailmeLink = "Email Me A Link";
exports.backTo = "Back to";
exports.verifyYourMail = "Verify Your Email";
exports.checkMail = "Check your email";
exports.setConfirmation = "We've sent a confirmation email.";
exports.clickTheLink = "Click the link in the email to complete your signup and start exploring.";
exports.resendMailButton = "Resend e-mail";
exports.apiEndPointcreateAccount = "account/accounts";
exports.longPass = "Long password? Hard to type?";
exports.checkMailText = "We've sent a confirmation email.\nClick the link in the email to complete your signup and start exploring.";
exports.verifyMailText = `Verify Your Email`;
exports.weMailedText1 = "We've sent a magic link to your email. Please check your inbox and click the link to log in. If you don't see the email, check your spam or junk folder.";
exports.weMailedText = "We emailed a magic link to ";
exports.clickLink = "Click the link to log in.";
exports.magicLinkSent = "Magic Link Sent!";
exports.magicLinkEndpoint = "/account/accounts"
exports.postAPIMethod = "POST";
exports.privacyAndTermsEndpoint = "/bx_block_static_pages/terms_privacy_url"


// Customizable Area End
